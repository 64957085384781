.slide {
  text-align: left;
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  position: relative;
  transition: left 0.5s ease 0.25s;
  /* flex-wrap: wrap; */
}
.slide p {
  font-size: 18px;
}
.slide h4 {
  font-size: 18px;
}
.slide .left,
.slide .right {
  width: 48%;
}

.slide h2 {
  margin-top: 0;
  font-size: 48px;
}
.slide .left {
  margin: auto 0;
}
.slide img {
  width: 100%;
  position: relative;
  right: 0;
  top: 0;
  transition: right 0.5s ease, top 0.5s ease;
}
.slide.active img.shift {
  right: -20px;
  top: -20px;
}

@media only screen and (max-width: 1025px) {
  .slide p {
    font-size: 16px;
  }
  .slide h4 {
    font-size: 16px;
  }
  .slide h2 {
    font-size: 30px;
  }
  .symbolHeading {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .slide .left,
  .slide .right {
    width: 100%;
  }
  .slide {
    flex-direction: column-reverse;
  }
}
