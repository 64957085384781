p.starWars {
  font-size: 15px;

  padding-right: 20px;
  line-height: 1.4em;
}
p.starWars.light {
  color: #000000b3;
}
p.starWars.dark {
  color: #ffffffb3;
}
