a.blueButton {
  font-family: "PT Mono", monospace;
  text-transform: uppercase;
  display: inline-block;
  color: white;
  line-height: 24px;
  font-size: 24px;
  padding: 20px 40px;
  align-items: center;
  background-color: #002dff;
  text-decoration: none;
  transition: opacity 0.3s 0s, top 0.8s 0s;
}
a.blueButton span.buttonLeft {
  display: inline-block;
}
@keyframes fadeAcrossBlue {
  0% {
    left: 0px;
    opacity: 1;
  }
  10% {
    left: 0px;
    opacity: 1;
  }
  49% {
    left: 20px;
    opacity: 0;
  }
  51% {
    left: -20px;
    opacity: 0;
  }
  90% {
    left: 0px;
    opacity: 1;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
a.blueButton span.buttonRight {
  display: inline-block;
  margin-left: 40px;
  top: 0;
  left: 0;
  position: relative;
  opacity: 1;
}
a.blueButton:hover span.buttonRight {
  animation: fadeAcrossBlue 1.5s ease infinite;
}
