.aboutMe {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.aboutMe h2.about {
  padding-right: 40px;
  margin: 0;
  max-width: 50%;
}
.iconCards {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.aboutMe div.about {
  width: 48%;
}
.aboutMe p {
  font-family: "karla", sans-serif;
  font-size: 15px;
  margin: 0;
}
.aboutMe h4 {
  margin: 0;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1025px) {
  .aboutMe {
    display: block;
  }
  .aboutMe h2.about {
    width: 100%;
    max-width: none;
    padding-bottom: 50px;
    padding-right: 75px;
    box-sizing: border-box;
  }
  .aboutMe div.about {
    width: 47%;
    max-width: none;
  }
  .iconCards {
    justify-content: space-between;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .aboutMe h2.about {
    font-size: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 660px) {
  .aboutMe div.about {
    width: 100%;
  }
  .iconCards {
    flex-wrap: wrap;
  }
}
