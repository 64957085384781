.endorsement {
  width: 30%;
  text-align: left;
}
.endorsement img {
  width: 50px;
}
.endorsement .image {
  width: 50px;
  height: 50px;
  /* border: 1px solid black; */
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  align-self: center;
}
.endorsement .endorsementFooter {
  display: flex;
}
.endorsement .name {
  font-size: 15px;
  font-weight: 700;
}
.endorsement .title {
  font-size: 14px;
  font-family: "PT Mono", serif;
  font-weight: 700;
}

@media only screen and (max-width: 1025px) {
  .endorsement .endorsementFooter {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .endorsement {
    width: 100%;
  }
  .endorsement .endorsementFooter {
    display: flex;
  }
}
