.card {
  position: relative;
}
.card a {
  bottom: 49px;
}
.card p {
  padding-bottom: 20px;
  font-size: 15px;
}
.card h3 {
  font-size: 24px;
}
