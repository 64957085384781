.hobby {
  color: rgba(255, 255, 255, 0);
  /* height: 400px; */
  background-position: center center;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0);
  background-blend-mode: multiply;
  transition: background-color 0.2s ease;
  position: relative;
  opacity: 0;
  top: -40px;
  width: 33.3%;
}

.hobby.visible:after {
  content: "";
  height: 100%;
  width: 10px;
  display: block;
  position: absolute;
  left: 0px;
  top: 0;
}

.hobby.visible:nth-child(1):after {
  background-color: red;
}
.hobby.visible:nth-child(2):after {
  background-color: green;
}
.hobby.visible:nth-child(3):after {
  background-color: blue;
}

.hobby.visible:nth-child(1) {
  transition: top 0.3s ease, opacity 0.3s ease;
  opacity: 1;
  top: 0px;
}
.hobby.visible:nth-child(2) {
  transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.3s;
  opacity: 1;
  top: 0px;
}
.hobby.visible:nth-child(3) {
  transition: top 0.3s ease 0.6s, opacity 0.3s ease 0.6s;
  opacity: 1;
  top: 0px;
}
.hobby.hover {
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 768px) {
  .hobby {
    color: rgba(255, 255, 255, 1);
    background-position: center center;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: multiply;
    transition: background-color 0.2s ease, height 0.2s ease;
    position: relative;
    opacity: 0;
    top: -40px;
    width: 100%;
  }
  .hobby p {
    display: none;
  }
  .hobby.active p {
    display: unset;
  }
  .hobby h2 {
    margin: 15px 0;
  }
  .hobby.active {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .hobby.visible:after {
    content: "";
    height: 10px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0px;
    top: 0;
  }
  .hobby.visible:nth-child(1),
  .hobby.visible:nth-child(2),
  .hobby.visible:nth-child(3) {
    transition: top 0.3s ease, opacity 0.3s ease;
  }
}
