@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");
.typingTextBox {
  box-sizing: border-box;
  max-width: 1160px;
  margin: 0 auto;
}
.typingTextBox.larger {
  padding-top: 221px;
}
.typingTextBox .caps {
  text-transform: uppercase;
}
.typingTextBox .intro {
  font-family: "PT Mono", monospace;
  font-weight: 700;
  font-size: 14px;
}
.typingTextBox .largeFont {
  font-family: "Karla", sans-serif;
  font-size: 84px;
  font-weight: 700;
  margin: 0;
}
.underline {
  position: relative;
}
.underline::after {
  content: "";
  height: 10px;
  width: 100%;
  background-color: #002dff;
  position: absolute;
  bottom: 11px;
  left: 0;
}
.blink {
  animation: blink-animation 0.5s steps(5, start) infinite;
}
@keyframes blink-animation {
  /* to {
    visibility: hidden;
  } */
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (max-width: 1260px) {
  .App .section.typingTextBox {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 1025px) {
  .typingTextBox .largeFont {
    font-size: 64px;
  }
  .underline::after {
    bottom: 7px;
  }
}
@media only screen and (max-width: 768px) {
  .typingTextBox .largeFont {
    font-size: 50px;
  }
  .underline::after {
    bottom: 4px;
    height: 8px;
  }
}
@media only screen and (max-width: 500px) {
  .typingTextBox .largeFont {
    font-size: 40px;
  }
  .underline::after {
    bottom: 5px;
    height: 5px;
  }
}
@media only screen and (max-width: 378px) {
  .typingTextBox .largeFont {
    font-size: 36px;
  }
}
