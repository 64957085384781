.projects h4 {
  text-align: left;
}
.controlContainer {
  display: flex;
  justify-content: space-between;
}
.controlContainer h2 {
  margin: 0;
  width: 390px;
}
.controls {
  margin: auto 0;
  display: flex;
  position: relative;
}
.slider {
  padding-top: 100px;
}
.slideCounter {
  display: flex;
  align-self: center;
  padding: 0;
  margin: 0;
}
.slideCounter li {
  list-style: none;
  content: "";
  color: rgba(255, 255, 255, 0);
  display: inline-block;
  height: 2px;
  width: 20px;
  background-color: gray;
  margin: 2px;
}
.slideCounter li.active.light {
  background-color: #002dff;
}
.slideCounter li.active.dark {
  background-color: #00f7ff;
}
