@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");
nav .navLeft {
  display: flex;
}
nav .navLeft .logo {
  height: 44px;
  margin: 8px 20px;
}
nav .navRight {
  display: flex;
  justify-content: space-between;
  min-width: 30%;
}
nav .navLeft .companyName {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 15px 10px 10px 10px;
}
nav div.container {
  padding: 30px 0px;
  display: flex;
  justify-content: space-between;
  max-width: 1160px;
  margin: 0 auto;
}
nav.light {
  background-color: white;
}
a.navLink {
  text-decoration: none;
  font-size: 14px;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 22px 0px 22px 40px;
  display: inline-block;
}
nav {
  width: 100%;
}
.black {
  color: black;
}
.white {
  color: white;
}
nav.sticky a:nth-child(4) {
  margin-right: 20px;
}
nav.sticky {
  position: fixed;
  z-index: 200;
  left: 0px;
  top: -73px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  max-width: none;
  transition: top 0.3s ease, background-color 0.3s ease;
}
nav.sticky.display {
  top: 0;
  /* box-shadow: 0 0 10px 0 #0000001a; */
}
nav.sticky .container {
  width: 1160px;
  padding: 0;
}
nav.dark {
  background-color: #17151d;
}
.activator,
.navFooter {
  display: none;
}

@media only screen and (max-width: 768px) {
  .container {
    height: auto;
  }
  /* .links {
  } */
  nav {
    background-color: #002dff;
  }
  nav .navRight .navFooter {
    display: flex;
    visibility: hidden;
  }
  nav .navRight.active .navFooter {
    visibility: visible;
    flex-direction: column;
    padding: 0 0 50px 0;
  }
  nav .navRight {
    transition: opacity 0.1s ease;
    background-color: #002dff;
    opacity: 0;
    /* display: none; */
    visibility: hidden;
  }
  nav div.container {
    padding: 0;
  }
  nav .logo {
    margin: 0 0 20px 20px;
  }
  nav .navLeft.active {
    display: none;
  }
  nav .navRight .links {
    display: flex;
    flex-direction: column;
    /* height: 0px; */
  }
  nav .navRight.active .links {
    display: flex;
    /* height: auto; */
  }
  nav .navRight.active {
    padding-top: 80px;
    box-sizing: border-box;
    visibility: visible;
    display: flex;
    opacity: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    color: white;
    overflow: auto;
  }
  nav .navRight a {
    margin: -20px 40px 20px;
    transition: margin 600ms ease, opacity 600ms ease;
    opacity: 0;
    font-size: 0;
  }
  nav .navRight.active a {
    opacity: 1;
    padding: 0;
    font-size: 50px;
    color: white;
    margin-top: 0;
  }
  .activator {
    position: absolute;
    right: 20px;
    top: 13px;
    display: block;
  }
  body.menuActive {
    overflow: hidden;
  }
  nav .navRight .navFooter {
    overflow: auto;
    margin-bottom: -100px;
    opacity: 0;
    transition: margin-bottom 600ms ease 400ms, opacity 600ms ease 500ms;
  }
  nav .navRight.active .navFooter {
    margin-bottom: 0;
    opacity: 1;
  }
  .navFooter h3 {
    font-family: "PT Mono", serif;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0 40px;
  }
  nav .navRight.active .navFooter a {
    font-size: 20px;
    text-decoration: none;
    padding: 8px 0 0 0;
    margin: 0 40px;
  }
}
@media only screen and (max-width: 660px) {
  nav .navRight.active a {
    font-size: 30px;
  }
}
