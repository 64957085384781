.info {
  display: flex;
  text-align: left;
}
.info .left,
.info .right {
  width: 50%;
}
.info .right.dark {
  background-color: #17151d;
  color: white;
}
.info .right.dark a,
.info .left.dark a {
  color: white;
}

.info .right.light {
  background-color: #fff;
  color: black;
}
.info .right.light a,
.info .left.light a {
  color: black;
}

.info .right {
  padding: 50px;
  opacity: 0;
  position: relative;
  right: -20px;
  transition: opacity 0.5s ease, right 0.5s ease;
}
.info .right.active {
  opacity: 1;
  right: 0;
}
.info .right p {
  margin: 0;
  padding-bottom: 20px;
}
.info .right p.header {
  font-size: 24px;
  font-weight: 700;
}

.info .right .blueButton {
  font-size: 15px;
}
.info .left {
  padding: 50px 50px 50px 0;
}
.info .infoCont {
  display: flex;
}
.address,
.email {
  width: 50%;
}
.address li,
.email li {
  list-style: none;
  margin-bottom: 5px;
}
.game {
  font-size: 14px;
  font-family: "PT Mono", serif;
  font-weight: 700;
  position: absolute;
  bottom: 50px;
  left: 0;
  margin: 0;
}
.address ul,
.email ul {
  padding: 0;
}
.info .left {
  position: relative;
}
.info .left h4 {
  margin: 0 0 20px 0;
}
.info .left .email a {
  text-decoration: none;
}
.info .left a {
  color: black;
}
.socials,
.miniNav,
.rights {
  display: flex;
}
.socials img {
  height: 36px;
  padding-right: 20px;
}
.miniNav a {
  padding: 0 20px;
  text-decoration: none;
  align-self: center;
}
.rights p {
  margin: 0;
  align-self: center;
}

@media only screen and (max-width: 1025px) {
  .info {
    flex-direction: column-reverse;
  }
  .info .left,
  .info .right {
    width: 100%;
    box-sizing: border-box;
  }
  .game {
    position: unset;
    padding-top: 30px;
  }
}
@media only screen and (max-width: 660px) {
  .info .right p.header {
    font-size: 22px;
  }
}
