.standOut {
  display: flex;
  text-align: left;
}
.underHeading {
  display: flex;
}
.standOut .left {
  width: 60%;
  margin: 0 80px 0 0;
}
.standOut h2 {
  margin: 0 0 50px 0;
  font-size: 48px;
}
.standOut p {
  font-size: 18px;
}
.standOut .blueButton {
  position: absolute;
  bottom: -32px;
  right: 30px;
  font-size: 15px;
}
.standOut .right {
  position: relative;
  width: 40%;
}
.standOut img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  animation: blurIn 0.5s ease 1;
}

@media only screen and (max-width: 1025px) {
  .standOut h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .starWars {
    display: none;
  }
  .standOut p {
    font-size: 16px;
  }
  .standOut a.blueButton {
    padding: 18px 30px;
    bottom: -30px;
    width: 271px;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 660px) {
  .standOut {
    flex-direction: column-reverse;
  }
  .standOut .right,
  .standOut .left {
    width: 100%;
  }
  .standOut img {
    height: 300px;
  }
  .left {
    padding-top: 60px;
  }
  .standOut a.blueButton {
    bottom: -26px;
  }
}
