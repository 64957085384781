.mission img {
  width: 100%;
  animation: blurIn 0.5s ease 1;
}
.mission {
  position: relative;
  padding: 100px 0;
}
.missionStatement {
  box-sizing: border-box;
  transition: opacity 0.5s ease, bottom 0.8s ease;
  width: 645px;
  position: absolute;
  bottom: -20px;
  right: 50px;
  padding: 50px;
  text-align: left;
  opacity: 0;
}
.missionStatement.light {
  background-color: #17151d;
  color: white;
}
.missionStatement.dark {
  background-color: white;
  color: #17151d;
}

.missionStatement.active {
  opacity: 1;
  bottom: 0;
}
.mission .statement {
  font-size: 18px;
}
.mission .missionFooter {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.mission .name {
  font-size: 20px;
  margin: 10px 0 5px 0;
}
.mission .title {
  font-size: 11px;
  font-family: "PT Mono", serif;
  margin: 0;
}
.mission a {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 13px;
}
.missionStatement .statement:nth-child(1) {
  margin-top: 0;
}

@media only screen and (max-width: 1025px) {
  .mission img {
    padding-bottom: 40px;
  }
  .mission .statement {
    font-size: 16px;
  }
  .missionStatement {
    padding: 40px;
    width: 450px;
    right: 20px;
  }
  .mission {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 768px) {
  .largeSection.mission {
    padding: 60px 0;
  }
  .missionStatement {
    width: 100%;
    position: relative;
    right: 0;
    bottom: -20px;
  }
  .missionStatement.active {
    bottom: 44px;
  }
}
@media only screen and (max-width: 500px) {
  .largeSection.mission {
    padding: 60px 0;
  }
}
